.client-screens {
	position: relative;

	&__buttons {
		gap: $spacing;
	}

	&__screens {
		
		&--wrapper {
			height: 100%;
			overflow: hidden;
			position: absolute;
				left: 35%;
			width: 70%;
			z-index: 5;

			&::after {
				background: url('/images/laptop-frame.png') no-repeat left;
					background-size: auto 100%;
				content: '';
				height: 100%;
				position: absolute;
					top: 0;
					left: 0;
				width: 100%;
				z-index: 6;
			}
		}
	}

	&__screen {
		height: 100%;
		opacity: 0;
		overflow: hidden;
		position: absolute;
			top: 3%;
			left: 6%;
		width: 100%;

		img { @include object-fit(cover, top left); }

		&.active {
			opacity: 1;
		}
	}

	&__button {
		appearance: none;
		background: transparent;
		border: none;
		border-bottom: 4px solid transparent;
		height: 0;
		padding-bottom: 20%;
		position: relative;
		transition: .25s border-color, .25s background;
		width: calc(50% - #{$spacing / 2});

		&.active {
			background: rgba($black, .2);
			border-color: $coral;
		}

		img {
			@include object-fit(contain, center);
			height: 40%;
			width: 70%;
		}
	}

	@include media($screen-sm-max, 'max') {

		&__screens {
			margin-top: 3rem;

			&--wrapper {
				height: 0;
				left: auto;
				right: -20vw;
				padding-bottom: 65%;
				position: relative;
				width: calc(100% + 20vw);
			}
		}

		&__screen {
			height: 88%;
			top: 3%;
			left: 6%;
			width: 72%;
		}
	}

	@include media($screen-sm) {

		&__buttons {
			align-self: start;
			gap: 3rem 1.5rem;
			padding-right: $spacing;
			width: 35%;
		}

		&__screens {
			height: 0;
			padding-bottom: 50vw;
			position: relative;
			width: 65%;

			&--wrapper {
				left: 0;
				width: calc(100% + 18vw);
			}
		}

		&__screen {
			height: 44vw;
			width: 61vw;
		}
	}

	@include media($screen-md) {

		&__screens {

			&--wrapper {

				
			}
		}

		&__screen {
			height: 42vw;
			top: 6%;
			left: 8%;
			width: 58vw;
		}
	}

	@include media($screen-lg) {

		&__screen {
			left: 8.5%;
			top: 6.5%;
		}
	}

	@include media(82.5rem) {

		&__screen {
			left: 9%;
		}
	}

	@include media(95rem) {

		&__screen {
			left: 10%;
			top: 6%;
		}
	}
}