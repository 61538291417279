.basic-testimonial {
	.contain {

	}

	&__author {
		strong {
			border-left: 1px solid black;
			margin-left: .75rem;
			padding-left: .75rem;
		}
	}

	@include media($screen-sm) {
		.contain {display: flex; align-items: center;}

		&__icon {
			background: url('/icons/gradient-circle.svg') no-repeat center;
				background-size: cover;
			display: flex;
				align-items: center;
				justify-content: center;
			height: 9rem;
			margin-right: 3rem;
			min-height: 9rem;
			min-width: 9rem;
			width: 9rem;

			svg {height: 3rem;}
		}
	}
}