/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
$libraryPath: '';

// Shades of gray
$white: #ffffff;
$black: #000;
$blackLighter: #0A162A;
$gray: #414E5B;
$grayDarker: #404144;
$grayDarkest: #1B1D24;
$grayLighter: #F2F3F5;

// Colors
$blue: #153567;
$blueMed: #263753;
$blueDarker: #19273e;
$blueDarkest: #050C1A;
$blueLighter: #1869B9;
$teal: #7ACBC6;
$tealDarker: #3CA39D;
$tealLighter: #D4F1EF;
$coral: #F18977;

$primaryColor: $blue;
$secondaryColor: $teal;

// Body Font Vars
$font-nunito-sans: "Nunito Sans", "Helvetica", "Arial", "Verdana", sans-serif;

$bodyFont: $font-nunito-sans;
$headerFont: $font-nunito-sans;

$textColor: $blueDarkest;
$bodyFontSize: 1em;
$bodyLineHeight: 1.55;
$ratioScale: 1.3195;
$linkColor: $textColor;
$dbsOrange: #ed5c41;
$spaced: .25rem;

// Heading Sizing
$headingColor: $textColor;
$headingFontSize: 1em;
$headingLineHeight: 1.2;

// Layout Vars
$containSizeMoreNarrow: 52rem;
$containSizeNarrow: 62rem;
$containSize: 80rem;
$containSizeWide: 88.889rem;

$spacing: 1.5rem;
$vSpacing: 1rem;
$hSpacing: $spacing * 2;

// Media Query Break Points
$screen-xs:  30.000em;  //  480px
$screen-sm:  48.000em;  //  768px
$screen-md:  62.000em;  //  992px
$screen-lg:  75.000em;  // 1200px
$screen-xlg: 100.000em; // 1600px

// Max Breakpoints
$screen-xs-max:  $screen-xs  - .001em;
$screen-sm-max:  $screen-sm  - .001em;
$screen-md-max:  $screen-md  - .001em;
$screen-lg-max:  $screen-lg  - .001em;
$screen-xlg-max: $screen-xlg - .001em;

$mobileNavBP:    $screen-md; // Break mobile nav styles
$mobileNavBPMax: $screen-md-max;

// Animatinos
$animationDuration: .75s;

/**
 * Type style definitions
 */
%default-type {
	color: $textColor;
	font-family: $bodyFont;
	letter-spacing: 0.025em;
}

%button-type {
	@extend %default-type;
	font-weight: 900;
}

%header-type {
	color: $headingColor;
    font-family: $bodyFont;
	font-weight: 300;
	line-height: $headingLineHeight;
}
