.intro-three-col {

	&__cta {
		margin-bottom: 3rem;
	}

	&__column {
		margin-bottom: $spacing;
	}

	&__icon {
		@include size(5rem);
		margin-bottom: $spacing;
		overflow: hidden;
		position: relative;

		img,
		svg {
			@include object-fit(contain, left);
		}
	}

	@include media($screen-sm) {

		&__column {
			margin-bottom: 0;
			width: calc(33.33% - #{$spacing/2});
		}
	}

	@include media($screen-md) {

		&__cta {
			border-right: 1px solid $grayLighter;
			margin-bottom: 0;
			margin-right: 3rem;
			padding: 3rem 2rem 3rem 0;
		}

		.flex-row-r-md {

			.intro-three-col__cta {
				border-left: 1px solid $grayLighter;
				border-right: none;
				margin-left: 3rem;
				padding-left: 3rem;
				padding-right: 0;
			}
		}
	}
}