.basic-cta {
	
	&__wrapper {
		background: $teal;
		padding: $spacing;
		width: 100%;
	}

	.button {
		display: block;
		margin: 0 auto -3.5rem;
		max-width: 20rem;
	}

	@include media($screen-sm) {
		&__wrapper {
			display: flex;
				align-items: center;
			padding: 4rem;
			width: calc(100% - 4rem)
		}

		h3 {margin: 0;}

		.button {margin: 0 -8rem 0 auto;}

		&__text {
			max-width: 42rem;
			padding-right: 2rem;
		}
	}
}