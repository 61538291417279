/**
 * Inherits most styling from layout-why-dbs.scss
 */

 .faqs.why-dbs {

    .faqs__item {
        border-bottom: 1px solid #BCC2C8;

        &:first-child {
            border-top: 1px solid #BCC2C8;
        }

        &-toggle {
            display: block;
            font-weight: 700;
            line-height: 1.4;
            padding: $spacing  3rem $spacing .75rem;
            position: relative;
            text-align: left;
            width: 100%;

            &:after {
                content: url('#{$libraryPath}/icons/caret-down.svg');
                position: absolute;
                    top: 50%;
                    right: .75rem;
                transform: translateY(-50%);
            }

            &.active:after {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        &-answer {
            height: 0;
            margin-bottom: 0;
            overflow: hidden;
            padding: 0 .75rem;
            visibility: hidden;
            transition: .25s height;

            &.active {
                margin-bottom: 1rem;
                visibility: visible;
            }

			&-text {
				padding-top: 1rem;
			}
        }
    }

    .expand-all {
        .collapse {display: none;}

        &.active {
            .collapse {display: inline;}
            .expand {display: none;}
        }
    }

	&.bg-dark {
		.faqs__item-toggle {
			color: $white;

			&::after {
				content: url('#{$libraryPath}/icons/caret-down-white.svg');
			}
		}
	}
}