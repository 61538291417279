.full-width-slider {

	&__slide {
		margin: 0 1.5rem;
		width: 100%;
	}

	&__element {
		margin: 0 auto;
		max-width: 62rem;
	}

	.flickity-page-dots {
		margin: 0 auto;
		max-width: 45rem;

		.dot {
			border-radius: 0;
			flex: 1;
			height: 2px;
			margin: 0;
			opacity: 1 !important;
			width: auto;

			&.is-selected {
				height: 6px;
			}
		}
	}

	.flickity-viewport {overflow: visible;}

	@include media($screen-sm) {

		&__slide {
			margin: 0 3rem;
		}
	}
}

