.service-header {
	padding-top: 3rem;

	h1 {font-weight: 100;}

	.portfolio-grid {
		margin-top: 0;

		&__wrapper {
			.eyebrow {display: none;}
		}

		.button--inline {font-size: 1.25rem;}
	}

	.breadcrumbs {
		position: relative;
			top: -1.5rem;
	}

	@include media( $screen-md-max, 'max' ) {
		.portfolio-grid {
			display: flex;
				flex-wrap: nowrap;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;

			&__item {
				height: 18rem;
				min-width: 18rem;
				margin-right: .75rem;
				padding-bottom: 0;
				width: 18rem
			}

			&__wrapper {
				background: $white;
				margin: 4rem 0 0 -#{$spacing};
				padding: 2rem 0 0 1.5rem;
				width: calc(100% + 3rem);
			}
		}
	}

	@include media($screen-md) {
		padding: 0 !important;
		margin-bottom: 10rem !important;

		h1 { @include font-scale(4); }

		.breadcrumbs {
			top: -3rem;
		}

		&__container {
			display: flex;
				justify-content: space-between;
		}

		&__text {
			padding: 5rem 4rem 5rem 0;
			width: 45%;
		}

		.portfolio-grid {
			height: calc(100% + 5rem);

			&__item {
				padding-bottom: 44.75%;
				width: 50%;
			}

			&__wrapper {
				margin-right: -1.5rem;
				position: relative;
				width: calc(55% + 1.5rem);
			}
		}

	}

	@include media($screen-lg) {
		h1 { @include font-scale(5); }

		&__text {
			width: 40%;
		}

		.portfolio-grid {
			&__wrapper {
				width: calc(60%);

				.eyebrow {
					display: block;
					position: absolute;
						top: .5rem;
						left: -1.5rem;
					transform: rotate(90deg);
					transform-origin: left;
				}
			}
		}
	}
}