/**
 * Note: A lot of styles are borrowed from the "Services" layout. These are just what is different.
 */

 .icon-blocks.expertise {
	.expertise__right {
		display: block;
		position: relative;
		z-index: 2;
	}

	.expertise__block {
		border-bottom: 1px solid lighten($gray, 10%);
		padding: $spacing;
		position: relative;

		&:last-child {border: none;}

		&-background {
			background: linear-gradient(135deg, #020A19 10%, #263854 100%);
			height: 100%;
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;

			img {
				height: 100%;
				object-fit: cover;
				opacity: .125 !important;
				width: 100%;
			}
		}

		.eyebrow {@include font-scale(1);}

		.button--inline {display: block;}
	}

	@include media($screen-md) {
		.expertise__right {width: 50%;}

		.expertise__block {padding: 2rem;}
	}
}


@include media($screen-sm) {
	.expertise__block .button--inline:after {top: -50%;}

	// When it follows the overflowing Portfolio layout...
	.portfolio-feature + .expertise {
		margin-top: 9rem;

		@include media($screen-md) {
			margin-top: 10rem;

			.expertise__right:after {
				height: calc(100% + 15rem);
				top: -10rem;
			}
		}
	}
}