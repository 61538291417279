.hero-with-form {

	&__top {
		padding-bottom: 5rem;
		padding-top: 6rem;

		&--content {
			margin-bottom: 3rem;
		}
	}

	&__bottom {
		margin-top: 5rem;
	}

	&__form {
		background: #293747;
		margin-top: 7rem;
		padding: $spacing;

		button[type=submit] {
			@include button ($blueDarkest, $white);
			padding-bottom: 1rem;
			padding-top: 1rem;
		}

		form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text],
		form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email],
		form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel],
		form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea {
			background: #1e2834 !important;
			border: 1px solid #A9AEB4 !important;
			border-radius: 1px !important;
			color: white !important;
			font-weight: 600 !important;
			

			&::placeholder {
				color: white;
				font-weight: 600;
			}
		}

		form.freeform-form .freeform-row .freeform-label,
		.freeform-row [class*="freeform-col-"].floating textarea:placeholder-shown:not(:focus) + * {
			color: white !important;
		}

		form.freeform-fieldtype-checkbox {
			color: white;
			display: flex;
		}

		form.freeform-form .freeform-fieldtype-rich-text,
		form.freeform-form .freeform-fieldtype-html {
			color: white !important;

			p,
			span {
				color: white !important;
			}
		}


		.freeform-row [class*="freeform-col-"].floating input:placeholder-shown:not(:focus) + * {
			color: white !important;
		}

		.freeform-row .floating label {
			background: none !important;
			color: $white !important;
			font-weight: 600 !important;

			&::after {color: $white !important}
		}

		&--image {
			margin: -6rem auto 2rem;
			max-width: 23rem;
		}
	}

	@include media($screen-sm) {
		position: relative;

		&__top {

			&--content {
				margin-bottom: 0;
				width: 50%;
			}
		}
	
		&__bottom {
			
			&--content {
				width: 40%;
			}
		}
	
		&__form {
			max-width: 33rem;
			position: absolute;
				top: 4rem;
				left: calc(50% + 5rem);
			width: 40%;
		}
	}
}