/**
 * Flex Blocks Layout
 * DBS>Interactive
 */
 .flex-blocks {

	&__heading {
		margin: 0 auto 3rem;
		max-width: 45rem;
	}

	&__content {
		display: flex;
			flex-wrap: wrap;
		margin: 0 -#{$spacing};
	}

	.cell {
		padding-top: 1em;
		width: 100%;

		@include media ($screen-sm) {
			width: 50%;
		}

		@include media ($screen-md) {
			width: 33.33%;
		}
	}

	hr {
		background: linear-gradient( to right, $teal, $coral );
		border: none;
		height: 1px;
		margin: $spacing auto $spacing 0;
		width: 6rem;
	}

	// Global cell class overrides
	@include media($screen-sm-max, 'max') {
		.flex-blocks__content {margin: 0;}

		.cell {
			border-bottom: 1px solid rgba($white, .3);
			padding: $spacing 0 !important;

			&:last-child {
				border: none;
				padding-bottom: 0 !important;
			}
		}
	}

	&.two-up {
		// Remove padding from last row cells to prevent excess space
		@include media($screen-sm) {
			.cell:nth-last-child(-n+2) {
				padding-bottom: 0;
			}
		}

		@include media ($screen-md) {
			.cell {width: 50%;}
		}
	}

	&.three-up {
		// Remove padding from last row cells to prevent excess space
		@include media($screen-sm) {
			.cell:nth-last-child(-n+2) {
				padding-bottom: 0;
			}
		}
		
		@include media($screen-md) {
			.cell:nth-last-child(-n+3) {
				padding-bottom: 0;
			}
		}
	}

	&.four-up {
		// Remove padding from last row cells to prevent excess space
		@include media($screen-sm) {
			.cell:nth-last-child(-n+2) {
				padding-bottom: 0;
			}
		}
		
		@include media($screen-md) {
			.cell:nth-last-child(-n+3) {
				padding-bottom: 0;
			}

			.cell {width: 25%}
		}
	}

	&.five-up {
		// Remove padding from last row cells to prevent excess space
		@include media($screen-sm) {
			.cell:nth-last-child(-n+2) {
				padding-bottom: 0;
			}
		}
		
		@include media($screen-md) {
			.cell:nth-last-child(-n+3) {
				padding-bottom: 0;
			}

			.cell {width: 20%}
		}
	}

	&.check-flex {
		@include media($screen-sm) {
			.cell {
				padding-left: 3rem;
				position: relative;
	
				&:before {
					content: url('#{$libraryPath}/icons/checkmark.svg');
					height: 1.4rem;
					position: absolute;
						left: 1rem;
					width: 1.4rem;
				}
			}
		}
	}
}
