/**
 * Slider ACF Layout Styles
 * DBS>Interactive
 */

 .layout-slider {
	overflow: visible;
	padding: 0em 1em;
}

.slider-layout__wrapper {
	margin: 0rem auto;
	max-width: $containSize;
	overflow: hidden;
	position: relative;
}

.slider-cell {
	min-height: 100%;
	width: 100%; // Set number of visible slides by width percentage. This is one slide.

	// Styles for a "background" style image with text overlay
	&.image-slide {
		.slider-cell__image {
            height: 100%;
            position: relative;
			width: 100%;
			z-index: 1;

			img {
				height: 100%;
				object-fit: cover;
				position: absolute;
					top: 0;
					left: 0;
				width: 100%;
			}
		}

		.slider-cell__content {
			position: relative;
			z-index: 2;

			h2, h3, h4, h5, p, a {color: $white;}
		}
	}

	&__content {
		padding: 1em;
	}
}

// Nav buttons
.flickity-button {

}

// Slider Styles
.slide-wrapper {
    overflow: hidden;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 1%;
    @include media($screen-md) {
        margin-bottom: 0;
        width: 100%;
    }
}
.slide__image {
    overflow: hidden;
    padding: 42%;
    min-height: 6em;

    @include media($screen-md) {
        padding: 30%;
    }

    img {
        position: absolute;
            left: 0;
            top: 0;
    }
}

.flickity-page-dots {
    display: flex;
        align-items: center;
        justify-content: space-between;
    position: relative;
}

.button-group {
    display: flex;
        align-items: center;
            justify-content: center;
}

// white circle styles for the dots
button.dot {
    border-radius: 100%;
    width: .75em;
    height: .75em;
    padding: 0;
    margin: 0em .5em;
    opacity: 1;
    background: transparent;
    transform: scale(1);
    border: .1em solid $white;
    &:focus, &:hover,
    &.is-selected {
        background-color: $black;
        border-color: $white;
        transform: scale(1.5);
        transition: transform .25s linear;
    }
}

// Pause/Start button styles
.pause-start-slider {
    background: none;
    border: none;
    margin: 0.5em .5em;
    height: 2em;
    width: 2em;
    position: relative;

    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        content: url("/icons/pause-symbol.svg");
    }

    &.slider-paused:after,
    &.slider-paused-from-control:after {
        content: url("/icons/play-symbol.svg");
    }

    &:focus, &:hover {
        transition: transform .25s ease-in-out;
        transform: scale(1.2);
    }
}

.flickity-viewport,
.flickity-slider,
.flickity-enabled {
    &:focus {
        box-shadow: none;
    }
}

.flickity-viewport {
    transition: height .25s ease-in-out;
}

// Custom Buttons
.slider-button {
    background: $teal;
    border: 0;
    line-height: 0;
    padding: 2.5rem .5rem;

    svg {
        height: 1.25rem;
        width: auto;
    }

    &.prev {
        svg {transform: rotate(180deg);}
    }
}