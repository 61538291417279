/**
 * Form Styles
 * DBS>Interactive
 */


fieldset,
.hidden_label,
.hide-label { 
	> label.gfield_label_before_complex,
    legend.gfield_label_before_complex {
        display: none;
    }

	> label, legend {
		@include size(1px);
		border    : 0;
		clip      : rect(0 0 0 0);
		margin    : -1px;
		overflow  : hidden;
		padding   : 0;
		position  : absolute;
	}
}


/**
 * Input Field Styles
 */

textarea {
	min-height: 5em;
}

input[type=radio] {
	margin-right: .5em;
}

select {
	min-width: 25%;
	margin: 0;
}

// Text and text-like input fields
textarea,
input[type=text],
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
select {
	display: block;
	border: 1px solid rgba($blueMed, .42);
	border-radius: 6px;
	max-width: 100%;
	padding: .75em;
	width: 100%;
}

fieldset {
	margin-bottom: $spacing*2;
}


/**
 * Remove IE's clear so we don't conflict with the add-clear button functionality 2017-09-12
 */
input[type=text]::-ms-clear {
	display: none;
}


// Hidden Fields
.g-id,
.user-path {display: none !important;}