.layout.who-contact { // Needs specificity to override global layout margins
	margin-bottom: 15rem;

	@include media($screen-sm) {margin-bottom: 5rem;}
}

.who-contact {

	&__top {
		margin-bottom: 2rem;

		&-icon {margin-bottom: $spacing;}
	}

	&__form {
		background: $teal;
		margin: 0 auto -14rem;
		max-width: 23rem;
		padding: $spacing;

		h3 {
			color: $blueDarkest;
			font-size: 7vw;
		}

		.button.arrow {
			display: block;
			margin-bottom: -3.85rem;
			margin-top: 2rem;
		}

		button[type=submit] {
			@include button ($blueDarkest, $teal);
			padding-bottom: 1rem;
			padding-top: 1rem;
		}

		form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text],
		form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email],
		form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel],
		form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea {
			border-radius: 6px !important;
			border: 1px solid rgba(38, 55, 83, .42) !important;
			font-weight: 600 !important;
			
			&::placeholder {
				font-weight: 600;
			}
		}

		.freeform-row .floating label,
		.freeform-row label {
			background: none !important;
			font-family: $font-nunito-sans !important;
			font-weight: 600 !important;
			opacity: 1 !important;
		}

		.freeform-fieldtype-rich-text {font-family: $font-nunito-sans;}
	}

	@include media($screen-xs) {
		h3 {
			@include font-scale(3);
		}
	}

	@include media($screen-sm) {

		&__top {
			display: flex;
				align-items: center;
			margin-bottom: 0;

			&-icon {
				padding-right: 3rem;
				width: 50%;
			}

			&-text {width: 50%;}
		}

		&__form {
			background: $white;
			box-shadow: 0 1px 4px 0 rgba(5,12,26,0.38);
			margin: 0 auto -7.5rem;
			max-width: none;

			h3 {color: $blueLighter;}
		}
	}

	@include media($screen-md) {

		&__form {
			display: flex;
			padding: 2.5rem;

			&-text {
				padding-right: 3rem;
				width: 50%;

				h3 {max-width: 30rem;}
			}

			// Form div
			.mobile-hidden {width: 50%;}

			.textarea {max-height: 8rem;}
		}
	}
}

// Home Page Variant Without Form
.page- .who-contact {
	@include media($screen-sm-max, 'max') {
		margin-bottom: 0;
	}

	@include media($screen-sm) {
		.who-contact__top {justify-content: center;}

		.who-contact__top-icon {
			margin-bottom: 0;
			max-width: 24rem;
			padding-right: 4.5rem;
		}

		&:after {
			background-position: -20vw 50%;
			background-size: auto 300%;
		}
	}
}