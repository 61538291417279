/**
 * Page Header Flex Layout
 * DBS>Interactive
 */

 .page-header {
	margin-top: 0 !important;

	h1:not(.eyebrow) {font-weight: 100;}

	&__container {
		display: flex;
			flex-direction: column;
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	&__image,
	&__content {
		padding: 3rem 0;
	}

	// &__content--wrapper {animation: opacity $animationDuration ease-in-out both, slideRight $animationDuration ease-out both;}
	&__content--wrapper {animation: opacity $animationDuration ease-in-out both;}

	&__image {
		img {
			display: block;
			width: 100%;
		}
	}

	.border-button {margin-top: 3rem;}

	.breadcrumbs {
		position: relative;
			top: -1.5rem;
	}

	@include media($screen-sm-max, 'max') {
		&__image {max-width: 25rem;}

		&__content {
			margin-bottom: $spacing;
			padding-bottom: 0;
		}
	}

	
	@include media($screen-sm) {
		.breadcrumbs {
			text-align-last: left;
			top: -3.5rem
		}

		&__container {
			align-items: flex-end;
			flex-direction: row;
			justify-content: space-between;
		}

		&__content {
			padding: 5rem 0;
			width: 55%;
		}

		&__image {
			align-self: center;
			max-width: 33rem;
			padding: 3rem;
			width: 45%;
		}

		&.text-center {
			.page-header__content {
				width: 100%;

				&--wrapper {
					animation: opacity $animationDuration ease-in-out both, slideUp $animationDuration ease-out both;
					margin: 0 auto;
					max-width: 46rem;
				}
			}

			.page-header__image {display: none;}
		}
	}


	/**
	 * CMS Classes
	 */

	&.teal-accent {
		.page-header__image {
			position: relative;

			&:after {
				background: $teal;
				content: '';
				position: absolute;
					top: 3rem;
					bottom: 3rem;
					left: 0;
				width: 100%;
				z-index: -1;
			}
		}

		@include media($screen-sm-max, 'max') {
			.page-header__container {flex-direction: column-reverse;}

			.page-header__content {padding-bottom: 2rem;}

			.page-header__image {
				margin: 0 -#{$spacing};
				max-width: none;
				padding: 0;

				&:after {
					left: 6rem;
					bottom: -1rem;
				}
			}
		}

		@include media($screen-sm) {
			.page-header__content {padding-right: $spacing * 2;}
			.page-header__image {
				align-self: stretch;
				padding: 0 0 0 $spacing;

				img {
					@include object-fit(cover, center);
				}
			}
		}
	}

	&.halved {
		@include media($screen-sm) {
			.page-header__content,
			.page-header__image {
				width: 50%;
			}
		}
	}

	&.reverse-order {
		@include media($screen-sm-max, 'max') {
			.page-header__content {
				margin-bottom: 0;
				padding-bottom: $spacing * 2;
				order: 2;
			}

			.page-header__image {
				margin-bottom: $spacing;
				padding-bottom: 0;
			}
		}
	}

	&.chevron-bg {
		background: $grayDarkest;

		&::after {

			@include media($screen-sm) {
				background-size: auto 300%;
				background-position: -20vw 50%;
			}
		}
	}
    
    &.hide-breadcrumbs {
        .breadcrumbs {
            display: none;
        }
    }
}
