.why-dbs {

    &__download {
        display: flex;
            align-items: center;
        margin-bottom: $spacing * 2;
        padding: $spacing;

        &-icon {
            @include size(4rem);
            margin-right: 1rem;
            min-width: 4rem;

            img {width: 100%;}
        }

        .button--inline {max-width: 15rem;}
    }

    &__blog {
        display: flex;
        margin-bottom: $spacing;
        
        &-img {
            background: $blueDarkest;
            height: 7rem;
            margin-right: 1rem;
            min-width: 7rem;
            overflow: hidden;
            position: relative;
            width: 7rem;

            img {
                @include object-fit(cover, center);
            }
        }

        .button--inline {
			padding: 0 0 $spacing;

			&:after {
				bottom: 0;
				left: 0;
				right: auto;
				top: auto;
				transform: translate(0,0);
			}

			&:hover, &:focus {&:after {transform: translate(.5rem, 0);}}
		}
    }

    @include media($screen-sm-max, 'max') {
        &__left {
            margin-bottom: $spacing;
        }
    }

    @include media($screen-sm) {
        &__container {
            display: flex;
                align-items: center;
                justify-content: center;
        }

        &__left {
            width: 60%;
        }

        &__right {
            font-size: .85rem;
            padding-left: $spacing * 2;
            width: 40%;
        }

        &__blog {

            &-img {
                height: 10rem;
                min-width: 10rem;
                width: 10rem;
            }
        }
    }
}