.portfolio-feature {
	padding-top: $vSpacing*2;
	position: relative;

	&::before {
		background: $grayDarkest;
		content: '';
		height: 40%;
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
	}

	&__block {
		margin-bottom: $spacing;
		padding: $spacing;
		position: relative;

		&--image {
			@include size(100%);
			position: absolute;
				top: 0;
				left: 0;
			
			img { @include object-fit(cover, bottom); }

			&::before {
				background: linear-gradient(to bottom, transparent, rgba($black, .95));
				content: '';
				height: 60%;
				position: absolute;
					bottom: 0;
					left: 0;
				width: 100%;
				z-index: 1;
			}
		}

		&--content {
			position: relative;
			z-index: 3;
		}

		&--spacer {
			padding-top: 75%;
		}
	}

	@include media($screen-sm) {
		padding-top: $vSpacing*3;

		.contain {
			gap: 1rem;
		}

		&__block {
			margin-bottom: 0;
			width: 33.33%;

			&--image img { @include object-fit(cover, center); }
			
			&--spacer {
				padding-top: 150%;
			}
		}
	}
}