.contact-cta {
	padding: $vSpacing;

	.contain {
		position: relative;

		&:after {
			background: url('#{$libraryPath}/icons/chevron_large.svg') no-repeat;
				background-position: -50vw 80%;
				background-size: auto 150%;
			content: '';
			height: 100%;
			opacity: .19;
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
			z-index: 0;
	
			@include media($screen-sm) {
				background-size: auto 200%;
				background-position: -5vw 50%;
			}
		}
	}

	&__interior {
		margin: 0 auto;
		max-width: 55rem;
		padding: $spacing 0;
		position: relative;
		z-index: 2;

		.button.mobile-hidden,
		.button.desktop-hidden,
		.button.arrow {
			background-color: $teal;
			border-color: $teal;
			color: $blueDarkest;

			&::before {background-image: url('#{$libraryPath}/icons/arrow-teal.svg');}

			&:hover,
			&:focus,
			&:active {
				background-color: $blueDarkest;
				border-color: $teal;
				color: $teal;
			}
		}
	}

	@include media($screen-sm) {
		padding: $vSpacing*3;

		&__interior {
			padding: $spacing*2;
		}
	}

	@include media($screen-md) {
		&__interior {
			padding: $spacing*3;
		}
	}
}