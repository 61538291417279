.portfolio:not(.portfolio-feature) {

	.portfolio-filter {
		margin-bottom: 4.5rem;

		select {
			appearance: none;
			color: #3E767D;
			width: auto;
		}

		

		&__button {
			@include font-scale(4);
			background: none;
			border: none;
			color: #3E767D;
			display: inline-block;
			margin-top: .75rem;
			padding: 0 2em 0 0;
			position: relative;

			&:before {
				@include size(4rem);
				background: url('/icons/green-circle-down.svg') no-repeat right;
					background-size: auto 100%;
				content: '';
				position: absolute;
					right: 0;
					top: 50%;
				transform: translateY(-50%);
			}

			&:after {
				content: '';
				display: block;
				border-bottom: 1px solid #3E767D;
				transform: scaleX(0);
				transform-origin: 100% 0;
				transition: transform .25s ease-in-out;
			}

			&:hover,
			&:focus {
				&:after {
					transform: scaleX(1);
					transform-origin: 0 100%;
				}
			}
		}

		&__screen {
			@include size(100%);
			background: white;
			display: flex;
				align-items: flex-start;
				flex-direction: column;
				justify-content: center;
			max-height: 100vh;
			overflow: auto;
			padding-left: 1.5rem;
			position: fixed;
				top: 0;
				left: 0;
			transform: translate3d(0, -100%, 0);
			transition: transform .5s ease, visibility 0s .5s linear;
			visibility: hidden;
			z-index: 5000;

			a {
				color: #3E767D;
				display: inline-block;
				font-size: 1.65rem;
				font-weight: 400;
				margin-bottom: 3rem;
				opacity: 0;
				text-decoration: none;
				transform: translate3d(-3rem, 0, 0);
				transition: .65s transform ease-in-out, .65s opacity ease-in-out;
				
				&:after {
					content: '';
					display: block;
					border-bottom: 1px solid #3E767D;
					transform: scaleX(0);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}
	
				&:hover,
				&:focus {
					&:after {
						transform: scaleX(1);
						transform-origin: 0 100%;
					}
				}

				&:last-child {margin-bottom: 0;}

				&:nth-child(2) {transition-delay: .2s;}
				&:nth-child(3) {transition-delay: .35s;}
				&:nth-child(4) {transition-delay: .5s;}
				&:nth-child(5) {transition-delay: .65s;}
				&:nth-child(6) {transition-delay: .8s;}
			}

			&.active {
				transform: translate3d(0, 0, 0);
				transition: transform .5s ease, visibility 0s 0s linear;
				visibility: visible;

				a {
					opacity: 1;
					transform: translate3d(0,0,0);
				}
			}
		}

		&__close {
			@include size(4rem);
			background: url('/icons/close-symbol-white.svg') no-repeat center;
				background-color: #3E767D;
				background-size: 50%;
			border: none;
			border-radius: 50%;
			margin-bottom: 3rem;
			min-height: 4rem;
			padding: 0;
		}
	}

	.portfolio-grid__item {
		border-bottom: 1.5rem solid transparent;
		padding-bottom: 130%;

		&-background {
			transition: .5s transform ease-in-out;

			img {object-position: top;}
		}

		&-text {bottom: 0;}

		&-excerpt {
			max-height: initial;
		}

		a {
            font-weight: 700;
            text-decoration: none;
        }

		&:hover {
			.portfolio-grid__item-text {background: 0;}

			.portfolio-grid__item-excerpt {
				max-height: initial;
				padding-bottom: 0;
			}

			.portfolio-grid__item-background {transform: scale(1.05);}
		}
	}

	@media (max-height: 44rem) {
		.portfolio-filter__screen {
			padding: 1.5rem 0;
			justify-content: start;

			a {margin-bottom: 1rem;}
		}
	}

	@include media($screen-sm) {
		.portfolio-filter {
			padding-left: 1.5rem;

			&__screen {
				padding-left: 20vw;

				a {@include font-scale(4);}
			}
		}

		.portfolio-grid__item {
			padding-bottom: 75%;

			&:nth-child(odd) {border-right: .75rem solid transparent;}
			&:nth-child(even) {
				border-left: .75rem solid transparent;
				transform: translateY(-25%);
			}

			&-text {padding: 3rem;}
		}
	}

	@include media($screen-md) {
		margin-top: 9rem;

		.portfolio-filter {margin-bottom: 6rem;}

		.portfolio-grid__item {
			width: 50%;
		}
	}
}

.portfolio.layout {
	.portfolio-grid__item-excerpt {@include font-scale(2);}

	@include media($screen-sm) {
		.portfolio-grid__item-excerpt {@include font-scale(3);}
	}
}

/**
 * Re-usable items related to portfolio layouts/pages
 */

 .portfolio-grid {

	&__item {
		overflow: hidden;
		padding-bottom: 100%;
		position: relative;

		&-background,
		&-background:after {
			@include size(101%);
			position: absolute;
				top: -1px;
				left: 0;
		}

		&-background {
			
			&:after {
				background: linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.7) 100%);
				content: '';
			}
			
			img {
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}

		&-text {
			padding: $spacing;
			padding-bottom: 2rem;
			position: absolute;
				bottom: -1rem;
				left: 0;
			transition: background .35s ease-in-out;
			width: 100%;
			will-change: background;

			.button--inline {
				display: block;
				margin-bottom: 1rem;
			}
		}

		&-excerpt {
			@include font-scale(1);
			line-height: 1.3;
			max-height: 0;
			overflow: hidden;
			transition: max-height .35s ease-in-out;
			will-change: max-height;
		}

		&:hover,
		&:focus {
			.portfolio-grid__item-text {
				background-color: rgba($blueDarkest, .77);
			}

			.portfolio-grid__item-excerpt {
				max-height: 10rem;
			}
		}
	}

	@include media($screen-sm) {
		display: flex;
			flex-wrap: wrap;

		&__item {
			padding-bottom: 50%;
			width: 50%;
		}
	}

	@include media($screen-md) {
		&__item {
			padding-bottom: 33.33%;
			width: 33.33%;
		}
	}
}