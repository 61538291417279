.flex {display: flex;}

.flex-col {flex-direction: column;}
.flex-col-r {flex-direction: column-reverse;}
.flex-row {flex-direction: row;}
.flex-row-r {flex-direction: row-reverse;}

.wrap {flex-wrap: wrap;}
.nowrap {flex-wrap: nowrap;}

.aic {align-items: center;}
.aie {align-items: flex-end;}
.asc {align-self: center;}
.ase {align-self: flex-end;}
.ass {align-self: flex-start;}

.jcc {justify-content: center;}
.jce {justify-content: flex-end;}
.jcsa {justify-content: space-around;}
.jcsb {justify-content: space-between;}
.jcse {justify-content: space-evenly;}

.fb-25 {flex-basis: 25%;}
.fb-50 {flex-basis: 50%;}
.fb-75 {flex-basis: 75%;}

.gap {gap: $spacing;}
.gap-2 {gap: $spacing*2}

// Things that only apply on mobile devices
@include media($screen-sm-max, 'max') {
	.flex-sm {display: flex;}
	
	.flex-col-sm {flex-direction: column;}
	.flex-col-r-sm {flex-direction: column-reverse;}
	.flex-row-sm {flex-direction: row;}
	.flex-row-r-sm {flex-direction: row-reverse;}

	.wrap-sm {flex-wrap: wrap;}
	.nowrap-sm {flex-wrap: nowrap;}
	
	.aic-sm {align-items: center;}
	.aie-sm {align-items: flex-end;}
	.asc-sm {align-self: center;}
	.ase-sm {align-self: flex-end;}
	.ass-sm {align-self: flex-start;}

	.jcc-sm {justify-content: center;}
	.jce-sm {justify-content: flex-end;}
	.jcsa-sm {justify-content: space-around;}
	.jcsb-sm {justify-content: space-between;}
	.jcse-sm {justify-content: space-evenly;}

	.fb-25-sm {flex-basis: 25%;}
	.fb-50-sm {flex-basis: 50%;}
	.fb-75-sm {flex-basis: 75%;}
}

// Things that apply after iPad sizing
@include media($screen-sm) {
	.flex-tab {display: flex;}
	
	.flex-col-tab {flex-direction: column;}
	.flex-col-r-tab {flex-direction: column-reverse;}
	.flex-row-tab {flex-direction: row;}
	.flex-row-r-tab {flex-direction: row-reverse;}

	.aic-tab {align-items: center;}
	.aie-tab {align-items: flex-end;}
	.asc-tab {align-self: center;}
	.ase-tab {align-self: flex-end;}
	.ass-tab {align-self: flex-start;}

	.jcc-tab {justify-content: center;}
	.jce-tab {justify-content: flex-end;}
	.jcsa-tab {justify-content: space-around;}
	.jcsb-tab {justify-content: space-between;}
	.jcse-tab {justify-content: space-evenly;}

	.wrap-tab {flex-wrap: wrap;}
	.nowrap-tab {flex-wrap: nowrap;}

	.fb-25-tab {flex-basis: 25%;}
	.fb-50-tab {flex-basis: 50%;}
	.fb-75-tab {flex-basis: 75%;}
}

// Things that apply after large tablet sizing
@include media($screen-md) {
	.flex-md {display: flex;}
	
	.flex-col-md {flex-direction: column;}
	.flex-col-r-md {flex-direction: column-reverse;}
	.flex-row-md {flex-direction: row;}
	.flex-row-r-md {flex-direction: row-reverse;}

	.aic-md {align-items: center;}
	.aie-md {align-items: flex-end;}
	.asc-md {align-self: center;}
	.ase-md {align-self: flex-end;}
	.ass-md {align-self: flex-start;}

	.jcc-md {justify-content: center;}
	.jce-md {justify-content: flex-end;}
	.jcsa-md {justify-content: space-around;}
	.jcsb-md {justify-content: space-between;}
	.jcse-md {justify-content: space-evenly;}

	.wrap-md {flex-wrap: wrap;}
	.nowrap-md {flex-wrap: nowrap;}

	.fb-25-md {flex-basis: 25%;}
	.fb-50-md {flex-basis: 50%;}
	.fb-75-md {flex-basis: 75%;}
}

// Things that apply after 1200px
@include media($screen-lg) {
	.flex-lg {display: flex;}
	
	.flex-col-lg {flex-direction: column;}
	.flex-col-r-lg {flex-direction: column-reverse;}
	.flex-row-lg {flex-direction: row;}
	.flex-row-r-lg {flex-direction: row-reverse;}

	.aic-lg {align-items: center;}
	.aie-lg {align-items: flex-end;}
	.asc-lg {align-self: center;}
	.ase-lg {align-self: flex-end;}
	.ass-lg {align-self: flex-start;}

	.jcc-lg {justify-content: center;}
	.jce-lg {justify-content: flex-end;}
	.jcsa-lg {justify-content: space-around;}
	.jcsb-lg {justify-content: space-between;}
	.jcse-lg {justify-content: space-evenly;}

	.wrap-lg {flex-wrap: wrap;}
	.nowrap-lg {flex-wrap: nowrap;}

	.fb-25-lg {flex-basis: 25%;}
	.fb-50-lg {flex-basis: 50%;}
	.fb-75-lg {flex-basis: 75%;}
}