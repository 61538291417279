.portfolio-slider-cards {
	background: $blueDarkest;

	&__slides {
		position: relative;
	}

	&__slide {
		margin-right: $spacing;
		position: relative;
		width: 100%;
	}

	&__card {
		background: $white;
		margin: 6rem 1.5rem;
		max-width: 24rem;
		padding: $spacing;
		position: relative;
		z-index: 2;

		.arrow-link {margin-top: 3rem;}
	}
	
	&__icon {
		margin-bottom: 1rem;
		width: 8rem;

		img {width: 100%;}
	}

	&__background {
		@include size(100%);
		position: absolute;
			top: 0;
			left: 0;
		z-index: 1;
		
		img { @include object-fit(cover, center); }
	}

	&__foreground {
		display: none;
	}

	.flickity-button {
		border: 6px solid #191D24;
		border-radius: 0;
		top: 100%;
		height: 3.5rem;
		width: 3.5rem;
		
		svg {display: none;}

		&.next {
			background: url('/icons/slider-right.svg') no-repeat center;
				background-color: $white;
				background-size: 1rem;
			right: 1.5rem;
		}

		&.previous {
			background: url('/icons/slider-left.svg') no-repeat center;
				background-color: $white;
				background-size: 1rem;
			left: auto;
			right: 4.75rem;
		}
	}

	.flickity-viewport {
		overflow: visible;
	}
    
    &.port-slider-cols {
        background: transparent;
        
        h2 {
            color: #0E1B31 !important;
            font-size: 1rem;
            font-style: normal;
            font-weight: 900;
            line-height: 1.75;
            letter-spacing: 4px;
            text-transform: uppercase;
            margin-bottom: 3rem;
        }
        
        .portfolio-slider-cards__card {
            background: transparent;
            padding: 0;
            margin: 0;
            height: 100%;
            display: flex;
                flex-direction: column;
                justify-content: flex-end;
            
            a {
                margin-top: 0;
            }
            
            a,
            h3 {
                color: white;
            }
        }
        
        .portfolio-slider-cards__foreground {
            display: none;
        }
        
        .portfolio-slider-cards__slides::after,
        .portfolio-slider-cards__slides::before {
            content: none;
        }
        
        .portfolio-slider-cards__slide {
            width: 30%;
            margin-right: 2rem;
            height: 44rem;
            padding: 1.5rem 1.5rem 3rem;
            
            &::after {
                background: linear-gradient(to bottom,transparent 0,rgba(0,0,0,0.5) 100%);
                content: '';
                height: 100%;
                position: absolute;
                    top: 0;
                    left: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

	@include media($screen-sm) {

		&__slides {
			position: relative;

			&::before,
			&::after {
				background: $blueDarkest;
				content: '';
				height: 100%;
				position: absolute;
					top: 0;
				width: 100vw;
				z-index: 5;
			}
	
			&::before {
				right: 100%;
			}
	
			&::after {
				left: 100%;
			}
		}

		&__slide {

		}

		&__card {
			margin: 7.5rem auto 7.5rem 1.5rem;
			padding: 2rem;
		}

		&__foreground {
			display: block;
			height: calc(100% + 6rem);
			top: -3rem;
			left: 70%;
			bottom: -3rem;
			transform: translateX(-50%);
			width: 45%;
			z-index: 3;
			position: absolute;

			img { @include object-fit(contain, center); }
		}
	}

	@include media(73rem) {

		&__foreground {
			left: 50%;
		}
	}
    
    @include media($screen-sm-max, 'max') {
        &.port-slider-cols {
            .portfolio-slider-cards__slide {
                width: 45%;
                height: 79vw
            }
        }
    }
    
    @include media($screen-xs-max, 'max') {
        &.port-slider-cols {
            .portfolio-slider-cards__slide {
                width: 90%;
                height: 149vw
            }
        }
    }
}