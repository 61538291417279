.portfolio-blocks {
	position: relative;
	z-index: 3; // This will overlap the expertise layout sometimes (home page)

	h3 {
		margin-bottom: 0;
		max-width: 47rem;
		padding-right: 3rem;
	}

	&__heading {
		margin-bottom: $spacing * 2;
	}

	.button--box-arrow {min-width: 18rem;}
    
    .portfolio-grid__item-excerpt {
        color: #fff;
    }

	.portfolio-blocks__tagline {display: none;}

	.button--inline + .button--inline {display: none;}

	&.port-blocks-tall {
		padding-bottom: 0 !important;
		padding-top: 0 !important;
		
		.portfolio-blocks__heading,
		.portfolio-grid__item-excerpt {display: none;}
		.portfolio-blocks__tagline {display: block;}

		.portfolio-blocks__tagline + .button--inline {
			font-size: 1.125rem;
			
		}

		.button--inline.bold {
			letter-spacing: normal;
			text-transform: none;

			&::after {content: none;}

			+ .button--inline {display: inline-block;}
		}

		.portfolio-grid__item {
			border-bottom: 4px solid transparent;

			&:hover {
				.portfolio-grid__item-text {background: transparent;}
			}
		}

		.portfolio-grid__item-background img {object-position: top;}
	}

	@include media($screen-sm) {

		&__heading {
			display: flex;
				align-items: center;
				justify-content: space-between;
		}

		.portfolio-grid {
			margin-bottom: -10rem;
		}

		&.port-blocks-tall {

			.portfolio-grid {
				margin-bottom: 0;
				margin-left: -4px;
				margin-right: -4px;
			}

			.portfolio-grid__item {
				border-bottom: none;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				padding-bottom: 58%;
				width: 33%;

				&-text {
					bottom: 2rem;
				}
			}
		}
	}

	&.bg-light {
		background: $white;
		padding: 0;

		.eyebrow { color: $blueLighter !important; }

		.portfolio-grid {
			margin: 0 auto !important;
		}
	}
}
