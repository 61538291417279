/**
 * Mixin to create a multi-directional animation with opacity fade.
 *
 * @example: 
 * @include animate3D( fadeRight, -3rem, 0, 0, 0);
 * .slideRight {animation: fadeRight 1s ease 0s 1 both;}
 */
@mixin animate3D($name, $x, $y, $z, $opacityStart ) {
	@keyframes #{$name} {
		0% {
			opacity: $opacityStart;
			transform: translate3d($x, $y, $z);
		}
		100% {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}

/**
 * Several basic examples are below, but they are being kept commented out
 * to keep unnecessary code from making its way into production. For new animations
 * that involve a "transform: translate", use "translate3d" since it will perform better 
 * because of hardware acceleration.
 */

/**
 * Slide left to right and fade in from invisible 
 */
// @include animate3D( fadeRight, -3rem, 0, 0, 0);
// .fadeRight {animation: fadeRight 1s ease 0s 1 both;}

/**
 * Slide right to left and fade in from invisible 
 */
// @include animate3D( fadeLeft, 3rem, 0, 0, 0);
// .fadeLeft {animation: fadeLeft 1s ease 0s 1 both;}

/**
 * Slide up and fade in from invisible 
 */
// @include animate3D( fadeUp, 0, 3rem, 0, 0);
// .fadeUp {animation: fadeUp 1s ease 0s 1 both;}

/**
 * Slide down and fade in from invisible 
 */
// @include animate3D( fadeDown, 0, 3rem, 0, 0);
// .fadeDown {animation: fadeDown 1s ease 0s 1 both;}

/**
 * Fade in only
 */
// @include animate3D( fade, 0, 0, 0, 0);
// .fade {animation: fade 1s ease 0s 1 both;}

/**
 * Bounce
 */
// @keyframes bounce {
// 	10%, 20%, 30%, 40%, 50% {transform: translate3d(0,0,0);}
// 	15% {transform: translate3d(0,-30px,0);}
// 	25% {transform: translate3d(0,-15px,0);}
// 	35% {transform: translate3d(0,-5px,0);}
// 	45% {transform: translate3d(0,-2px,0);}
// }
// .bounce {animation: bounce 3s ease-in-out infinite both;}
  

/**
 * Not enough? This site has a lot of great animations with the ability to copy the
 * source code. https://animista.net/play/
 */
 
@keyframes opacity {
	0% {
		opacity: 0.01;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slideRight {
	0% {
		transform: translate3d(-3rem, 0, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideUp {
	0% {
		transform: translate3d(0, 3rem, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}