.icon-blocks {
	margin-top: 4rem;
	position: relative;

	&__block {
		background: $blueDarker;
		border-bottom: 1px solid rgba($teal, .4);
		display: flex;
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;
		padding: $spacing;

		&:last-child {border: none;}

		.eyebrow.desktop-hidden {
			width: calc(100% - 5.5rem);
		}

		.eyebrow a {font-weight: 900;}
	}

	&__left { margin-bottom: $spacing * 2; }

	&__icon {
		margin: 0 1rem .75rem 0;
		width: 4.5rem;
	}

	&__desc a {color: $white !important;}
    
    &__link {
        align-self: flex-end;
        margin: 1.5rem auto 0 0;
    }

	@include media($screen-sm-max, 'max') {
		.button--box-arrow {margin-top: 1.5rem;}
	}

	@include media($screen-sm) {

		&__right {
			display: flex;
				flex-wrap: wrap;
		}

		&__block {
			border-right: 1px solid rgba($teal, .4);
			width: 50%;

			&:nth-last-child(-n+2) {border-bottom: none;}

			&-heading {display: block;}
		}
	}

	@include media($screen-md) {
		margin-top: 0;

		&__container {display: flex;}

		&__left,
		&__right {width: 50%;}

		&__left {padding-right: 4rem;}

		&__right {
			&:after {
				height: calc(100% + 10rem);
				left: 6rem;
				top: -5rem;
			}
		}
	}


	/**
	 * Vertically stacked variant
	 */
	&.rows {
		.icon-blocks__block {
			background: linear-gradient(135deg, #020A19 0%, #263854 100%);
			border-bottom: 1px solid lighten($gray, 10%);
            position: relative;

			&:last-child {border: none;}
		}
        
        .icon-blocks__block-upper {
            display: flex;
            width: 100%;
        }
        
        .icon-blocks__link {
            bottom: 2rem;
            left: 7.5rem;
            right: unset;
            margin: 0;
            position: absolute;
        }
        
        @include media($screen-xs-max, 'max') {
            .icon-blocks__block-upper {
                padding-bottom: 1rem;
            }
            
            .icon-blocks__link {
                left: unset;
                right: 1rem;
            }
        }

		@include media($screen-sm) {
			.icon-blocks__block {
				flex-wrap: nowrap;
				width: 100%;
			}

			.icon-blocks__icon {margin: 0 $spacing 0 0;}

			.icon-blocks__text {
				width: 100%;

				.icon-blocks__desc {display: none;}

				.button--inline {display: block;}
			}
		}
        
        @include media($screen-sm-max, 'max') {
            .icon-blocks__text {
                display: none;
            }
        }
	}

	&.eight-blocks {
		.icon-blocks__block {
			flex-wrap: nowrap;
		}

		h3 {margin: 0;}

		.icon-blocks__icon {
			@include size(3rem);
			margin: 0 1rem 0 0;
		}
	}

	&.full-blocks {
		.icon-blocks__container {position: relative;}

		.icon-blocks__block .eyebrow.desktop-hidden {width: 100%;} // Don't allow it to wrap

		.icon-blocks__text {width: 100%;}
		
		@include media($screen-md) {
			.icon-blocks__block {align-items: flex-start;}
			
			.icon-blocks__left {
				padding: 0;
				width: 0;
			}

			.icon-blocks__right {
				width: 100%;

				&:after {left: 60%;}
			}
		}
	}

	&.two-up {
		@include media($screen-sm) {
			.icon-blocks__block {width: 50%;}
		}
	}

	&.three-up {
		@include media($screen-sm) {
			.icon-blocks__block {width: 33.33%;}
		}
	}

	&.four-up {
		@include media($screen-md) {
			.icon-blocks__block {width: 25%;}
		}
	}
}