.image-blurb {

	&__blurb {

	}

	&__text--wrap {
		border-left: 1px solid $teal;
		margin-top: 2rem;
		padding: 1rem $spacing;
	}

	.arrow-link.boxed {
		padding-right: 0;
		padding-left: 4rem;

		&::after {
			background-size: 1.25rem !important;
			left: 0;
		}
	}

	@include media($screen-sm) {
		&__image {width: 55%;}

		&__blurb {
			width: 45%;

			p:not(:last-child) {margin-bottom: 2rem;}
		}
	}
}