.interactive-content {

	&__links {

		.interactive-content__link {
			appearance: none;
			background: transparent;
			border: none;
			border-left: 1px solid transparent;
			border-bottom: 1px solid rgba($white, .25);
			color: white;
			display: block;
			font-weight: 600;
			padding: 1.25rem 1rem;
			position: relative;
			text-align: left;
			text-decoration: none;
			transition: .25s background, .25s border-left-color, .25s border-top-color, .25s border-bottom-color;
			width: 100%;

			&:first-child {
				border-top: 1px solid rgba($white, .25);
			}

			&::after {
				background: url('/icons/arrow-right-white-long.svg') no-repeat right;
				background-size: 1.5rem auto;
				content: '';
				height: 100%;
				opacity: .25;
				position: absolute;
					top: 0;
					right: 1rem;
				transition: .25s opacity, .25s transform;
				width: 1.5rem;
			}

			&:hover,
			&:focus {
				background: $blueDarkest;
				border-left-color: $coral;
				border-top-color: $blueDarkest;
				border-bottom-color: $blueDarkest;
				
				&::after {
					opacity: 1;
					transform: translate3d(.5rem, 0, 0);
				}
			}
		}
	}

	&__panes {
		position: relative;

		&--wrapper {
			border: .75rem solid black;
			border-radius: 34px;
			box-shadow: 8.801px 11.001px 22.333px 2.2px rgba(0, 0, 0, 0.5);
			height: 0;
			overflow: hidden;
			padding-bottom: 71%;
			position: relative;
			z-index: 3;
		}
	}
	
	.interactive-content__pane {
		background: $blueDarker;
		height: 0;
		overflow: hidden;
		padding-bottom: 75%;
		position: relative;

		img,iframe { @include object-fit(cover, center); }
	}

	&__colors {
		height: calc(100% + #{$spacing*2});
		overflow: hidden;
		position: absolute;
			top: 0;
			left: 30%;
		width: 200vw;

		&::before {
			background: linear-gradient(to bottom, $blueDarker, transparent);
			content: '';
			height: 50%;
			position: absolute;
			width: 100%;
			z-index: 2;
		}

		.interactive-content__color {
			height: 100%;
			width: 100%;
		}

		&--scroller {
			height: 100%;
			position: relative;
			width: 100%;
		}
	}

	@include media($screen-md) {

		&__links {
			padding-right: $spacing * 3;
			width: 35%;
		}

		&__panes {
			width: 65%;
		}

		&__colors {
			height: calc(150% + #{$spacing*3});
		}
	}

	+ .layout {
		position: relative;
		z-index: 2;
	}
}