.testimonials {
	position: relative;

	.eyebrow {margin-bottom: 3rem;}

	&__slide {
		width: 100%;

		span {line-height: 1.5;}
	}

	&__meta {
		display: flex;
			align-items: center;
	}

	&__img {
		border-radius: 50%;
		max-height: 4.5rem;
		max-width: 4.5rem;
		min-width: 4.5rem;
		overflow: hidden;
		width: 4.5rem;

		img {
			display: block;
			width: 100%;
		}
	}

	@include media($screen-sm-max, 'max') {
		&__slide {padding: 0 2rem;}

		&__nav {
			position: absolute;
				left: 0;
				top: 50%;
			transform: translateY(-50%);
			width: 100%;

			&-buttons {
				display: flex;
					justify-content: space-between;
			}
		}
	}

	@include media($screen-sm) {
		&__wrapper {
			display: flex;
				justify-content: space-between;
		}

		&__slide {
			display: flex;
				justify-content: space-between;
		}

		&__carousel {width: 80%;}

		&__icon {
			background: url('/icons/gradient-circle.svg') no-repeat center;
				background-size: cover;
			display: flex;
				align-items: center;
				justify-content: center;
			height: 8rem;
			margin-right: 3rem;
			min-height: 8rem;
			min-width: 8rem;
			width: 8rem;

			svg {height: 3.5rem;}
		}

		&__nav {
			border-left: 1px solid $teal;
			display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			margin-left: $spacing * 2;
			padding-left: $spacing * 2;
			width: 20%;

			&-buttons {display: flex;}

			.slider-button {
				background: 0;
				padding: 0 .75rem;

				&:first-child {
					border-right: 1px solid rgba($white, .3);
					padding: .25rem 1.5rem .25rem 0;
				}

				&:last-child {padding: .25rem 0 .25rem 1.5rem;}

				svg polygon {fill: $teal;}
			}
		}
	}

	@include media($screen-md) {
		&__icon {
			height: 9rem;
			min-height: 9rem;
			min-width: 9rem;
			width: 9rem;
		}
	}
}