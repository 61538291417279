.services {

	&__heading {
		margin-bottom: #{$spacing * 2};

		.eyebrow {color: $coral;}
	}

	&__left {
		margin-bottom: $spacing;
	}

	&__list {

		&-item {
			border-left: 4px solid transparent;
			cursor: pointer;
			transition: .25s;
			padding: #{$spacing * 2 / 3};
			position: relative;
			
			&:before {
				border-top: 4px solid $coral;
				content: '';
				opacity: 0;
				position: absolute;
					top: 50%;
					left: -12px;
				transform: translateY(-50%);
				transition: .25s;
				width: 8px;
			}

			&.active {
				background: black;
				border-color: $coral;
				color: $coral;

				&::before {opacity: 1;}
			}
		}
	}

	&__right {
		background: radial-gradient(42.27% 340.93% at 0% 50%, #000000 0%, rgba(0, 0, 0, 0) 100%);
		border-top: 1px solid #5B5B5B;
		padding: $spacing;
	}

	&__description {
		opacity: 0;
		position: absolute;
		transition: opacity 0s, visibility 0s;
		visibility: hidden;

		&.active {
			opacity: 1;
			position: static;
			transition: opacity .25s, visibility 0s;
			visibility: visible;
		}

		h4 {color: $coral;}
	}
    
    &.alt-ver {
        .services__heading {
            display: none;
        }
        
        .services__list {
            columns: 1;
            
            &-item.active {
                background: $teal;
                color: $black;
            }
        }
        
        .services__right {
            border-left: 3px solid $teal;
            background: #152641;
        }
    }

	@include media($screen-sm) {
		&__heading {
			margin-bottom: #{$spacing * 3};
		}

		&__main {
			display: flex;
				align-items: center;
		}

		&__left {
			margin-bottom: 0;
			padding-right: #{$spacing * 2};
			width: 60%;
		}

		&__list {

			&-item {
				font-size: 1.125rem;
			}
		}
	
		&__right {
			border-left: 1px solid #5B5B5B;
			border-top: none;
			padding: 0 #{$spacing * 2} 0;
			width: 40%;
		}

		&__description {
			padding: #{$spacing * 2} 0;
		}
        
        &.alt-ver {
            .services__left,
            .services__right {
                width: 50%;
			}
		}
		
        &.services-swap {
            .services__left {
                order: 1;
                padding-left: 3rem;
                padding-right: 0;
            }
            
            .services__right {
                background: linear-gradient(90deg, rgba(0,0,0,0) 0, #000 100%);
                padding: 0 3rem 0 0;
                border-left: none;
                border-right: 4px solid $coral;
            }
        }
	}
    
	@include media($screen-md) {
		padding-bottom: #{$spacing * 4} !important;
		padding-top: #{$spacing * 4} !important;

		&__list {
			columns: 2;
				column-gap: #{$spacing * 2};
		}
        
        &.alt-ver {
            .services__left {
                width: 60%;
            }
            
            .services__right {
                width: 40%;
            }
        }
	}
}