.phases {
	.eyebrow {color: $teal;}

	hr {
		background: linear-gradient( to right, $teal, $coral );
		border: none;
		height: 1px;
		margin: $spacing auto $spacing 0;
		width: 6rem;
	}

	&__top {
		margin-bottom: $spacing * 2;
		max-width: 59rem !important;
	}

	&__row {
		margin-bottom: $spacing * 2;
		position: relative;

		&:last-child {margin-bottom: 0;}

		ul {
			columns: 2;
			column-gap: 2rem;
			margin: 0;

			li {break-inside: avoid-column;}
		}
	}

	blockquote {
		border-left: 1px solid #7acbc6;
		margin: 2rem 0;
		overflow: hidden;
		padding: .75rem 1.5rem;
		position: relative;

		p {margin-left: 0;}
	}

	@include media($screen-sm) {
		&__container {
			display: flex;
				justify-content: space-between;
			max-width: 72rem !important;
		}

		&__left {
			padding-right: 3rem;
			width: 40%;
		}

		&__right {
			width: 60%;
		}

		&__row {
			padding-left: 6rem;
		}

		&__icon {
			@include size(4.5rem);
			position: absolute;
				top: 0;
				left: 0;
	
			img {width: 100%}
		}
	}

	&.two-up {
		@include media($screen-sm) {
			.phases__container {max-width: $containSize !important;}
			.phases__right {
				display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				width: 100%;

				.phases__row {
					width: calc(50% - 1.5rem);

					&:nth-last-child(-n+2) {margin-bottom: 0;}
				}
			}
		}
	}
}