/**
 * Re-usable items related to portfolio layouts/pages
 */

 .portfolio-grid {

	&__item {
		overflow: hidden;
		padding-bottom: 100%;
		position: relative;

		&-background,
		&-background:after {
			@include size(101%);
			position: absolute;
				top: -1px;
				left: 0;
		}

		&-background {
			
			&:after {
				background: linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.7) 100%);
				content: '';
			}
			
			img {
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}

		&-text {
			padding: $spacing;
			padding-bottom: 2rem;
			position: absolute;
				bottom: -1rem;
				left: 0;
			transition: background .35s ease-in-out;
			width: 100%;
			will-change: background;

			.button--inline {
				display: block;
				margin-bottom: 1rem;
			}
		}

		&-excerpt {
			line-height: 1.3;
			max-height: 0;
			overflow: hidden;
			transition: max-height .35s ease-in-out;
			will-change: max-height;
		}

		&:hover,
		&:focus {
			.portfolio-grid__item-text {
				background-color: rgba($blueDarkest, .77);
			}

			.portfolio-grid__item-excerpt {
				max-height: 10rem;
			}
		}
	}

	@include media($screen-sm) {
		display: flex;
			flex-wrap: wrap;

		&__item {
			padding-bottom: 50%;
			width: 50%;
		}
	}

	@include media($screen-md) {
		&__item {
			padding-bottom: 33.33%;
			width: 33.33%;
		}
	}
}

.portfolio-header {
    margin-top: 0;
    
    ul {
        columns: 2;
        list-style-type: none;
        padding-left: 0;
        
        li {
            a {
                font-weight: 400;
                text-decoration: none;
            }
            
            &:not(:last-child) {
                margin-bottom: $spacing;
            }
        }
    }

	&__image {margin-bottom: 4rem;}
    
    @include media($screen-sm) {
        &__container {
            display: flex;
        }
        
        &__content {
            padding-right: #{$spacing * 4};
            width: 65%;
        }
        
        &__content,
        &__services {
            &>*:first-child {
                margin-bottom: 2rem;
            }
        }
        
        &__services {
            width: 35%;
        }

		&__image {margin-bottom: 5rem;}
    }
    
    @include media($screen-sm-max, 'max') {
        &__content {
            margin-bottom: #{$spacing * 2};
            
            .h1 {
                font-size: 2.5rem;
            }
        }
    }
}
