.results {
	.contain {
		display: flex;
			flex-direction: column-reverse;
	}

	&__main {

		&-item {
			opacity: 0;
			padding-top: 1.5rem;
			position: absolute;
			transition: opacity 0s, visibility 0s;
			visibility: hidden;

			&::before {
				border-top: 6px solid $tealDarker;
				content: '';
				position: absolute;
					top: 0;
					left: 0;
				width: 5rem;
			}

			&.active {
				opacity: 1;
				position: relative;
				transition: opacity .25s, visibility 0s;
				visibility: visible;
			}
		}
	}

	&__list {
		list-style: none;
		margin-bottom: 2rem;
		padding: 0;

		&-item {
			cursor: pointer;
			line-height: 1.35;
			margin-bottom: 1.5rem;
			min-height: 3.65rem;
			padding-top: .5rem;
			padding-bottom: .5rem;
			padding-left: 5rem;
			position: relative;

			&:before {
				background: url('#{$libraryPath}/icons/blue-check-white-bg.svg') no-repeat left center;
					background-size: auto 100%;
				content: '';
				height: 4rem;
				position: absolute;
					top: 50%;
					left: 0;
				transform: translateY(-50%);
				width: 4rem;
				z-index: 2;
			}

			&::after {
				content: '';
				height: 100%;
				position: absolute;
					top: 0;
					left: 2rem;
				width: calc(100% - 5rem);
			}

			&.active {
				&:after {
					background: linear-gradient(90deg, rgba(122, 203, 198, 0.4) 0%, rgba(217, 217, 217, 0) 85.32%);
				}
			}
		}
	}

	@include media($screen-sm) {
		.contain {
			flex-direction: row;
		}

		&__main {
			padding-right: 3rem;
			width: 45%;

			&-item {font-size: 1.125rem;}
		}

		&__list {
			margin-bottom: 0;
			width: 55%;

			&-item {font-size: 1.125rem;}
		}
	}

	@include media($screen-md) {

		.contain {align-items: center;}

		&__main {
			display: flex;
				flex-direction: column;
				justify-content: center;
			padding-right: 3rem;
			min-height: 25rem;
			width: 40%;
		}

		&__list {
			columns: 2;
				column-gap: 3rem;
			width: 60%;

			&-item {
				break-inside: avoid-column;
			}
		}
	}
}